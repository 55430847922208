
import { defineComponent, ref } from 'vue';
import swal from 'sweetalert2';
import Q from 'q';
import Multiselect from '@vueform/multiselect';
import { generalStore } from '@/store';
import router from '@/router';
import { ProductFilter } from '@/models/Product';

export default defineComponent({
    components: { Multiselect },
    name: 'OrderFilter',
    data() {
        return {};
    },
    async setup() {
        const filter = ref<ProductFilter>(new ProductFilter());
        const sloterFilter = generalStore.getters.getCatalogFilter;
        if (sloterFilter.status != null) sloterFilter.status = +sloterFilter.status;
        filter.value = JSON.parse(JSON.stringify(sloterFilter));

        if (!generalStore.state.productTypes.length) await generalStore.dispatch('loadAllProductTypes');
        const productTypes = generalStore.state.productTypes;

        async function Search() {
            swal.showLoading();

            generalStore.commit('updateCatalogFilter', filter.value);
            router.push({ name: 'catalog', params: { newSearch: '1', keepFilter: '1' } });

            swal.close();
        }
        await Q.delay(400);
        swal.close();

        function ResetFilter() {
            generalStore.commit('updateCatalogFilter', new ProductFilter());
            router.push({ name: 'catalog' });
        }

        function Close() {
            router.push({ name: 'catalog' });
        }
        return {
            filter,
            productTypes,
            ResetFilter,
            Close,
            Search
        };
    }
});
